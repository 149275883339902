$dark: #212528;
$tile: #2A2E31;
$text: #DDD;

* {
	box-sizing: border-box;
	font-family: 'Work Sans', sans-serif;
	color: $text;
}

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	display: flex;
	flex: 1;
	background-image: radial-gradient( circle farthest-corner at -4% -12.9%,  rgb(55, 60, 62) 0.3%, rgb(31, 32, 36) 90.2% );


	#radio {
		display: flex;
		flex: 1;

		.App {
			display: flex;
			flex: 1;

			@import "loading";
			@import "patterns";

			.hidden{
				display: none !important;
			}
			.scroll-wrapper {
				display: flex;
				flex: 1;
			}
			.random-pattern{
				position: absolute;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
				mix-blend-mode: overlay;
				opacity: 0.15;
			}
			.shine{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: linear-gradient( 109.6deg,  rgba(white, 1) 11.2%, rgba(white, 0) 91.1% );
				mix-blend-mode: overlay;
				opacity: 0.5;
			}
			.stations {
				display: flex;
				flex: 1;
				padding: 1rem 1rem 12rem;
				flex-wrap: wrap;
				.station {
					position: relative;
					margin: 1rem;
					width: calc(25% - 2rem);
					height: 12vw;
					background-color: $tile;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					flex-direction: column;
					box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
					transition: all 0.3s cubic-bezier(.25,.8,.25,1);
					img{
						width: 70%;
						max-height: 50%;
						position: relative;
					}
					h3{
						font-size: 1.8rem;
						margin: 0 0 1rem;
						font-weight: bold;
						position: relative;
						color: white;
						text-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.4);
						+ img{
							width: 25%;
						}
					}
					&:hover {
						box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
					}
				}
			}
			.player{
				position: absolute;
				bottom: 0;
				left: 0;
				height: 10rem;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				canvas{
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 0;

				}
				.controls{
					z-index: 1;
					display: flex;
					align-items: center;
					position: relative;
					justify-content: center;
					flex: 1;
					height: 100%;
					background-color: darken(rgba($dark, 0.5), 10%);
					.playing{
						position: absolute;
						height: 8rem;
						top: 1rem;
						left: 1rem;
						display: flex;
						align-items: flex-start;
						width: 45%;
						.station{
							height: 100%;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 8rem;
							background-color: $tile;
							img{
								width: 80%;
							}
						}
						p{
							margin: 0 0 0 1rem;
							font-size: 2rem;
							width: 40%;
							text-transform: uppercase;
							line-height: 2rem;
						}
					}
					.play-pause{
						font-size: 4rem;
						display: flex;
						padding: 1rem;
						border-radius: 10rem;
						border: 1px solid rgba(white, 0.05);
						cursor: pointer;
						transition: all 0.35s;
						&:hover{
							background-color: rgba(white, 0.05);
							border: 1px solid rgba(white, 0.2);
						}
					}
				}
			}
		}
	}
}
@media (max-width: 1000px){
	body #radio .App .stations .station{
		width: calc(33.3333% - 2rem);
		height: 20vw;
	}
}
@media (max-width: 600px){
	body #radio .App .stations .station{
		width: calc(50% - 2rem);
		height: 30vw;
	}
}
